import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ProductOptionModel } from 'src/app/pages/online/produto/models/product-option.model';
import { ProductModel } from 'src/app/pages/online/produto/models/product.model';

@Component({
  selector: 'app-cartao-produto',
  templateUrl: './cartao-produto.component.html',
  styleUrls: ['./cartao-produto.component.scss']
})
export class CartaoProdutoComponent implements OnInit, OnChanges {

  habilitarIconeVideoConf: boolean;
  @Input() titulo: string;
  @Input() descricao: string;
  @Input() imagem: string;
  @Input() labelPeriodo: string;
  @Input() diferenciais: string[];
  @Input() produtos: ProductModel[];
  @Output() produtoSelecionadoEvt = new EventEmitter<ProductModel>();

  preco: number;
  produtoSelecionado: ProductModel;

  constructor() {}

  ngOnInit(): void {
    this.imagem = this.img(this.produtos);
    this.setarIconeVideoConf(this.produtos);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['produtos'].currentValue) {
      const produtos = (changes['produtos'].currentValue as ProductModel[]);
      this.produtoSelecionado = produtos[produtos?.length - 1];
      this.preco = this.produtoSelecionado?.preco;
    }
  }

  public validade(produto: ProductModel): string {

    if (produto.idMidiaTipo === 3) {
      return produto.periodoUso;
    } else {
      return produto.validade;
    }
  }

  private img(produtos: ProductModel[]): string {
    if (produtos.length > 0) {
      const produto = produtos[0];
      return this.recuperarImagemPorProduto(produto);
    } else {
      return this.recuperarImagemPorTituloCard();
    }
  }

  private recuperarImagemPorProduto(produto: ProductModel) {
    if (produto.idMidiaTipo === 3) {
      return 'assets/img/logoSiteID.png';
    } else if (produto.idProdutoTipo === 1) {
      return 'assets/img/e-CPF-safeweb.png';
    } else {
      return 'assets/img/e-CNPJ-safeweb.png';
    }
  }

  private recuperarImagemPorTituloCard() {
    if (this.titulo?.toUpperCase().includes('SAFEID')) {
      return 'assets/img/logoSiteID.png';
    } else if (this.titulo?.toUpperCase().includes('ECPF')) {
      return 'assets/img/e-CPF-safeweb.png';
    } else {
      return 'assets/img/e-CNPJ-safeweb.png';
    }
  }

  setarIconeVideoConf(produtos: ProductModel[]): void {
      if (produtos.length > 0) {
        const produto = produtos[0];
        this.habilitarIconeVideoConf = produto.idTipoEmissao === 3;
      }
  }

  selecionar(produto: ProductModel) {
    this.produtoSelecionado = produto;
    this.preco = produto.preco;
  }

  comprar() {
    this.produtoSelecionadoEvt.emit(this.produtoSelecionado);
  }

  public formatMoney(n) {
    if (n >= 0) {
      return 'R$ ' + n.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, '$1.');
    } else {
      return '-';
    }
  }

}
