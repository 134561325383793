import { Injectable } from '@angular/core';

import { CrcModel } from '../models/crc.model';

@Injectable({
    providedIn: 'root'
})

export class DataService {

    private config: CrcModel;
    constructor() { }

    setConfig(config: CrcModel) {
        this.config = config;
    }
    getConfig() {
        return this.config;
    }
}
