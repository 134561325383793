export const environment = {
  production: true,
  selectedProduct: null,
  protocol: "",
  overlay: false,
  isCompany: false,
  TYPE_PRODUCT: 0, // 0 - todos, 1 - CPF, 2 - CNPJ
  COMERCIAL_SITE_ID: 2,
  formPayment: null,
  PRODUCT_SITE: 5,
  PRODUCT_SITE_SAFEID: 1,
  EMISSION_TYPE: 3,
  STEP_NUMBER: 0,

  API_PRODUCTS: {
    link: `https://pss.safewebpss.com.br/Service/Microservice/Shared/Product/api`,
  },
  API_SOLICITATION: {
    link: "https://pss.safewebpss.com.br/Service/Microservice/Shared/Partner",
  },

  API_CATALOGO: {
    link: `https://pss.safewebpss.com.br/Service/Microservice/Shared/Catalogo/api`,
    autenticacao: `https://pss.safewebpss.com.br/Service/Microservice/Shared/Autenticacao/api/Authentication`,
  },
  URL_WEBCOMPONENT_ECOMMERCE: `https://acsafeweb.safewebpss.com.br/Asset/webcomponent/checkout/ecommerce-form.js?v=${new Date().getTime()}`,
};
