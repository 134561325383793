import { Component, OnInit } from '@angular/core';

import { CrcModel } from '../../../shared/models/crc.model';
import { DataService } from '../../../shared/services/data.service';

declare var $: any;

@Component({
    selector: 'app-intro-online',
    templateUrl: './intro.component.html',
    styleUrls: ['./intro.component.scss']
})

export class IntroComponent implements OnInit {

    config: CrcModel;

    constructor(public dataService: DataService) {}

    ngOnInit() {
        this.config = this.dataService.getConfig();
        $('.buy-click').click(() => {
            const element = $('#products').length > 0 ? $('#products') : $('#notfound');
            $('html, body').animate({
                scrollTop: element.offset().top
            }, 200);
        });
    }
}
