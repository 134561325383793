<header>
  <nav class="navbar navbar-expand-lg navbar-light p-3">
    <div class="container">
      <a href="#" class="navbar-brand" (click)="redirectHome()">
        <img src="assets/img/safeweb-logo.png" class="img-fluid" alt="Safeweb - Segurança da Informação" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation" (click)="this.closeMenu = !this.closeMenu">
        <span [ngClass]="this.closeMenu ? 'gg-close' : 'gg-menu-left'"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarMenu">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="navegarPara('parceria')">Parceria</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="navegarPara('produtos')">Preços</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="navegarPara('faq')">Perguntas Frequentes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="navegarPara('suporte')">Suporte</a>
          </li>
        </ul>
        <button class="btn btn-primary comprar" (click)="navegarPara('produtos')">Comprar</button>
      </div>

    </div>
  </nav>
</header>
