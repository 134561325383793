<section class="faq" id="faq">
  <div class="container py-5">
    <div class="row">
      <div class="col-md-12 text-center mb-4">
        <h2 class="title">Perguntas Frequentes</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item faq mb-2" *ngFor="let faq of listFaq">
            <h2 class="accordion-header" [id]="faq.heading">
              <button class="accordion-button collapsed card-header" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="faq.target" aria-expanded="true" [attr.aria-controls]="faq.collapse">
                {{ faq.p }}
              </button>
            </h2>
            <div [id]="faq.collapse" class="accordion-collapse collapse" [attr.aria-labelledby]="faq.heading" data-bs-parent="#accordionFAQ">
              <div class="accordion-body card-body">
                {{ faq.res }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
