import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
    public listFaq = [];

    ngOnInit() {
        this.faq();
    }
 
    faq() {
        this.listFaq = [
            { collapse: 'item1', heading: "heading1", target: "#item1", p: 'Como faço para enviar os documentos necessários para a emissão do Certificado Digital?', res: 'Após a confirmação do pagamento, você receberá um e-mail, com um link, para anexar a documentação necessária. Por isso, certifique-se de que o e-mail cadastrado corresponde ao do titular do Certificado Digital.' },
            { collapse: 'item2', heading: "heading2", target: "#item2", p: 'Como faço para agendar a videoconferência?', res: 'Após a aprovação da documentação enviada, a Safeweb entrará em contato com você, pelo telefone cadastrado na solicitação, para agendar um horário.' },
            { collapse: 'item3', heading: "heading3", target: "#item3", p: 'Quanto tempo dura a videoconferência?', res: 'O tempo médio é de 5 à 10 minutos. Como a videoconferência serve para confirmarmos seu cadastro, solicitamos que você esteja em um local reservado, livre de ruídos e bem iluminado, com seu documento de identificação em mãos (o mesmo que você enviou anteriormente).' },
            { collapse: 'item4', heading: "heading4", target: "#item4", p: 'Como eu acesso à videoconferência?', res: 'No horário previamente acordado com a Safeweb, você receberá um link para acessar à videoconferência no e-mail cadastrado na solicitação, juntamente com um código de acesso. Neste momento, um agente de registro já estará lhe aguardando na sala para realizar a confirmação de cadastro.' },
            { collapse: 'item5', heading: "heading5", target: "#item5", p: 'Como sei que meus documentos foram aprovados?', res: 'A Safeweb entrará em contato para informar que a documentação foi aprovada e agendar a videoconferência.' },
            { collapse: 'item6', heading: "heading6", target: "#item6", p: 'Quais os requisitos para realizar a videoconferência?', res: 'Você pode realizar a videoconferência utilizando seu celular ou computador. Para isso, é necessário: boa conexão com a internet, câmera​, microfone e navegador Google Chrome ou Safari atualizados. ​Solicitamos que você esteja em um ambiente reservado, com fundo neutro e com seu documento de identificação em mãos. <a target="_blank" href="https://statics.safeweb.com.br/imagens/safeweb/site-emissao-online/instrucao-videoconf.pdf">Clique aqui</a> para saber mais detalhes.' },
            { collapse: 'item7', heading: "heading7", target: "#item7",  p: 'Como devo enviar os documentos para realizar a videoconferência?', res: 'Você deve enviar seus documentos, conforme as orientações disponíveis <a target="_blank" href="https://statics.safeweb.com.br/imagens/safeweb/site-emissao-online/orientacoes-envio-doc.pdf">aqui</a>.' },
            { collapse: 'item8', heading: "heading8", target: "#item8", p: 'Quais os requisitos para realizar a instalação do Certificado Digital modelo A1 pelo Assistente de Certificado Digital?', res: 'Para realizar a instalação do Certificado Digital você precisará de um computador com sistema operacional windows 7 ou superior.' },
            { collapse: 'item9', heading: "heading9", target: "#item9", p: 'Posso fazer a videoconferência pelo celular?', res: 'Sim, desde que ele tenha uma câmera e conexão com a Internet. Basta você acessar o link enviado e autorizar o uso dos recursos de áudio e vídeo do seu celular.' },
            { collapse: 'item10', heading: "heading10", target: "#item10", p: 'No caso de e-CNPJ, qualquer sócio pode realizar a videoconferência?', res: ' Não. É necessário que seja o sócio responsável, cujo CPF esteja vinculado ao CNPJ da empresa, na Receita Federal do Brasil.' },
            { collapse: 'item11', heading: "heading11", target: "#item11", p: 'Quando eu preciso realizar uma videoconferência?', res: 'Você pode optar pela videoconferência para aquisição de Certificado Digital, nos modelos A1, e-CPF ou e-CNPJ (arquivo instalado no computador) ou para o modelo A3, e-CPF e e-CNPJ, em nuvem (SafeID) desde que você já tenha emitido um Certificado Digital e possua biometria cadastrada.' },
            { collapse: 'item12', heading: "heading12", target: "#item12", p: 'Quanto tempo demora para eu receber o retorno da validação dos documentos?', res: 'A Safeweb realizará a validação dos seus documentos em até 48 horas, após o envio dos mesmos.' },
        ];
    }  

    teste(ref: any) {
        const button = ref.lastChild
        if(!button.classList.contains('collapsed')) {
            button.classList.remove('collapsed')
        } else {
            
        }
    }
}
