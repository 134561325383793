import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private _checkoutSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public checkout$ = this._checkoutSubject.asObservable();

  constructor() {}

  updatecheckout(newCheckout: string[]) {
    this._checkoutSubject.next(newCheckout);
  }
}
