import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  //closeMenu = false;
  env: any;
  closeMenu: boolean = false;

  constructor(public router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.env = environment;
  }



  toggleMenu(): void {
    this.closeMenu = !this.closeMenu;
  }

  public close() {
    this.closeMenu = !this.closeMenu;
  }

  public navegarPara(anchor: string) {
    const urlAtual = this.router.url;
     if (urlAtual.includes("ecommerce")) {
      this.redirectHome();
    }

    const el = document.getElementById(anchor);
    el?.scrollIntoView({ behavior: "smooth" });
  }

  public redirectHome() {
     this.router.navigate(["/"]);
    window.scrollTo(0, 0);
  }

  fecharMenu(): void {
    this.closeMenu = true;
  }
}
