import {
  Component,
  Renderer2,
  ElementRef,
  OnInit,
  OnDestroy,
  AfterContentInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../../shared/services/data.service";
import { CheckoutService } from "src/app/shared/services/checkout.service";
import { CrcModel } from "src/app/shared/models/crc.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-ecommerce",
  templateUrl: "./ecommerce.component.html",
  styleUrls: ["./ecommerce.component.scss"],
})
export class EcommerceComponent implements OnInit, OnDestroy, AfterContentInit {
  env = environment;
  navigationSubscription: any;
  dadosCheckout;
  checkout;
  chamarWebcomponent = false;
  config: CrcModel;
  produtoSelecionado;
  token;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    public dataService: DataService,
    public router: Router,
    private checkoutService: CheckoutService
  ) {
    this.checkoutService.checkout$.subscribe((newCheckout) => {
      this.checkout = newCheckout;
    });
  }

  ngOnInit() {
    this.env.overlay = true;
    if (!this.checkout["dadosAr"]) {
      window.location.reload();
      window.history.back();
    }

    let produtoCrc = this.checkout["produto"]["crc"];

    this.dadosCheckout = {
      dadosAr: this.checkout["dadosAr"],
      listaProdutos: this.checkout["listaProdutos"],
      filtros: [],
      cnpjParceiro: this.checkout["cnpjAr"],
      codigoCatalogo: this.checkout["catalogo"],
      idCatalogoProduto: this.checkout["listaProdutos"][0]["idCatalogoProduto"],
      paginaRequisitora: 4,
      mostrarPesquisaSatisfacao: true,
      voucher: "",
      oabCodigo: "",
      documento: produtoCrc.documento,
      crc: {
        idCrc: produtoCrc.idCrc,
        idTipoCrc: produtoCrc.idTipoCrc,
        registro: produtoCrc.registro,
      },
    };

    if (this.dadosCheckout) {
      this.criarChamadaComponenteExterno(this.env.URL_WEBCOMPONENT_ECOMMERCE);
    }
  }

  carregamento() {
    this.env.overlay = false;
  }

  ngAfterContentInit() {
    setTimeout(() => {
      if (this.checkout["dadosAr"]) {
        this.chamarWebcomponent = true;
      }
    }, 2000);
  }

  criarChamadaComponenteExterno(url: string) {
    // Criar um elemento <script>
    const script = this.renderer.createElement("script");

    // Configurar o atributo src do script
    this.renderer.setAttribute(script, "src", url);

    // Anexar o elemento <script> ao corpo do documento
    this.renderer.appendChild(this.el.nativeElement, script);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
