import { Directive, HostListener, Input, ElementRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[swMask]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SwMaskDirective,
    multi: true
  }]
})

export class SwMaskDirective implements ControlValueAccessor {

  onTouched: any;
  onChange: any;

  // tslint:disable-next-line:no-input-rename
  @Input('swMask') swMask: string;

  constructor(private _el: ElementRef, private _renderer: Renderer2) { }

  getPad(): string {
    return this.swMask.replace(/\D/g, '').replace(/9/g, '_');
  }

  writeValue(value: any): void {
    if (value) {
      this._renderer.setProperty(this._el.nativeElement, 'value', this.applyMask(value));
    } else {
      this._renderer.setProperty(this._el.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {
    const valor = $event.target.value.replace(/\D/g, '');

    // retorna caso pressionado backspace ou delete
    if ($event.keyCode === 8 || $event.keyCode === 46) {
      this.onChange(valor);
      return;
    }

    if (valor.length <= this.getPad().length) {
      this.onChange(valor);
    }

    if (valor.length > this.getPad().length) {
      this.onChange(valor.substring(0, this.getPad().length));
      $event.target.value = this.applyMask(valor.substring(0, this.getPad().length));
      return;
    }

    $event.target.value = this.applyMask(valor);
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if (this.isValid($event.target.value)) {
      return;
    }
    this.onChange('');
    $event.target.value = '';
  }

  applyMask(valor: string): string {
    valor = valor.replace(/\D/g, '');
    const pad = this.getPad();
    const valorMask = valor + pad.substring(0, pad.length - valor.length);
    let valorMaskPos = 0;

    valor = '';
    for (let i = 0; i < this.swMask.length; i++) {
      if (isNaN(parseInt(this.swMask.charAt(i), null))) {
        valor += this.swMask.charAt(i);
      } else {
        valor += valorMask[valorMaskPos++];
      }
    }

    if (valor.indexOf('_') > -1) {
      valor = valor.substr(0, valor.indexOf('_'));
    }

    return valor.replace(/[?]/g, '');
  }

  isValid(valor: string): boolean {
    const optionals = this.swMask.replace(/[^?]/g, '').length;
    const mask = this.swMask.replace(/[?]/g, '').length;

    let ret = false;

    if (valor.length === mask) {
      ret = true;
    } else if (valor.length === (mask - optionals)) {
      ret = true;
    }

    return ret;
  }
}
