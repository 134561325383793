import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { RecaptchaModule } from 'ng-recaptcha';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask'


import { ModalComponent } from '../app/components/modal/modal.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CartaoProdutoComponent } from './components/cartao-produto/cartao-produto.component';
import { ContadorParceiroComponent } from './components/contador-parceiro/contador-parceiro.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { GratuidadeComponent } from './components/gratuidade/gratuidade.component';
import { HeaderComponent } from './components/header/header.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { ParceriaSafewebComponent } from './components/parceria-safeweb/parceria-safeweb.component';
import { SuporteTecnicoComponent } from './components/suporte-tecnico/suporte-tecnico.component';
import { SwMaskDirective } from './directives/mask-sw.directive';
import { EcommerceComponent } from './pages/ecommerce/ecommerce.component';
import { IntroComponent } from './pages/online/intro/intro.component';
import { OnlineComponent } from './pages/online/online.component';
import { ProdutoComponent } from './pages/online/produto/produto.component';
import { AuthInterceptor } from './shared/core/interceptor';
import { TooltipDirective } from './directives/tooltip.directive';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    OverlayComponent,
    FaqComponent,
    IntroComponent,
    ProdutoComponent,
    SuporteTecnicoComponent,
    GratuidadeComponent,
    ContadorParceiroComponent,
    ParceriaSafewebComponent,
    OnlineComponent,
    EcommerceComponent,
    SwMaskDirective,
    CartaoProdutoComponent,
    ModalComponent,
    TooltipDirective
  ],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    RecaptchaModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
