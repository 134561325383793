<app-overlay *ngIf="env.overlay"></app-overlay>

<section class="products py-5" id="produtos">
  <div class="container mb-3">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2 class="font-weight-bold title mb-4 mt-2">
          Garanta já seu Certificado Digital gratuito
        </h2>

        <p class="mb-4">
          Escolha abaixo o tipo e formato de emissão do seu Certificado Digital.
        </p>
      </div>
    </div>

    <div class="row mb-4 d-flex justify-content-center">
      <div
        class="mb-3 mb-md-0"
        [ngClass]="{
          'col-md-4': eCpfs?.length < 3,
          'col-md-6': eCpfs?.length > 2
        }"
      >
        <button
          class="btn-product-group btn-lg btn-block"
          (click)="changeProductGroup('CPF')"
          [ngClass]="{ 'btn-product-focus': currentGroup == 'CPF' }"
        >
          e-CPF
        </button>
      </div>
      <div
        class=""
        [ngClass]="{
          'col-md-4': eCpfs?.length < 3,
          'col-md-6': eCpfs?.length > 2
        }"
      >
        <button
          class="btn-product-group btn-lg btn-block"
          (click)="changeProductGroup('CNPJ')"
          [ngClass]="{ 'btn-product-focus': currentGroup == 'CNPJ' }"
        >
          e-CNPJ
        </button>
      </div>
    </div>
    <div class="row" *ngIf="eCpfs?.length > 0; else nenhumProduto">
      <div class="col-md-12">
        <div
          class="row-card d-flex justify-content-center flex-column flex-sm-row flex-md-row"
          *ngIf="currentGroup == 'CPF'"
        >
          <div
            class="col-xs-12 col-sm-12 col-md-4 mb-5 mb-md-0 mx-0 mx-sm-0 mx-md-0"
            *ngFor="let p of eCpfs"
          >
            <app-cartao-produto
              [titulo]="p.nome"
              [produtos]="p.produtos"
              [labelPeriodo]="p.labelPeriodo"
              [diferenciais]="p.diferenciais"
              (produtoSelecionadoEvt)="produtoSelecionado($event)"
            ></app-cartao-produto>
          </div>
        </div>
        <div
          class="row-card d-flex justify-content-center flex-column flex-sm-row flex-md-row"
          *ngIf="currentGroup == 'CNPJ'"
        >
          <div
            class="col-xs-12 col-sm-12 col-md-4 mb-5 mb-md-0 mx-0 mx-sm-0 mx-md-0"
            *ngFor="let p of eCnpjs"
          >
            <app-cartao-produto
              [titulo]="p.nome"
              [produtos]="p.produtos"
              [labelPeriodo]="p.labelPeriodo"
              [diferenciais]="p.diferenciais"
              (produtoSelecionadoEvt)="produtoSelecionado($event)"
            ></app-cartao-produto>
          </div>
        </div>
      </div>
    </div>
    <ng-template #nenhumProduto>
      <div class="col-md-12 mx-auto text-center">
        <h5>
          Não encontramos nenhum produto cadastrado. <br />
          Contate a Autoridade de Registro selecionada.
        </h5>
      </div>
    </ng-template>
  </div>
</section>

<ng-template #modal>
  <div class="modal-body">
    <div class="text-center px-5 py-3">
      Prezado cliente, para adquirir esse Certificado Digital através de <br />
      videoconferência é necessário <b>possuir a mídia (cartão ou token)</b
      ><br />
      compatível com a cadeia V2 ICP-Brasil para armazenamento do<br />
      Certificado Digital.
      <a
        href="https://statics.safeweb.com.br/imagens/safeweb/site-emissao-online/doc-midias-compativeis.pdf"
        target="_blank"
      >
        <img
          src="assets/img/icon-tooltip.svg"
          height="17"
          width="17"
          class="ml-1 mb-2"
          appTooltip mensagem="Conferir mídias compatíveis."
          posicao="bottom"
        />
      </a>
      <br /><br />

      Se você ainda não possui token ou cartão,
      <a [href]="linkPaginaEmissaoPresencialModal" target="_blank"
        >clique aqui</a
      >
      para adquirir seu<br />
      Certificado Digital por emissão presencial ou entre em contato pelos
      telefones (XX) 4007.2410/(51) 3018.0300, em horário comercial.
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center flex-column">
    <div>
      <p class="font-weight-bold mb-2">
        Deseja continuar com a compra desse Certificado Digital?
      </p>
    </div>
    <div>
      <button
        type="button"
        class="btn-close-modal btn-lg"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        Cancelar
      </button>
      <button
        type="button"
        class="btn-ok-modal btn-lg ml-2"
        (click)="comprar()"
      >
        Continuar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #modalConfirmacaoDados>
  <div class="modal-header">
    <h5 class="modal-title">Confirmação de dados</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formConfirmarDados">
      <div class="row">
        <div class="col">
          <label for="inputEmail4">Região</label>
          <input
            type="text"
            class="form-control"
            formControlName="uf"
            disabled
          />
        </div>
        <div class="col">
          <label for="inputEmail4">Tipo</label>
          <select
            class="form-select"
            id="exampleFormControlSelect1"
            formControlName="idTipoCrc"
          >
            <option value="1">Originário</option>
            <option value="2">Filial</option>
            <option value="3">Provisório</option>
          </select>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="numeroDocumento"
          >{{ (this.currentProduct.idProdutoTipo === this.productTypeEnum.eCPF || this.currentProduct.idProdutoTipo === this.productTypeEnum.ePF) ? 'CPF' : 'CNPJ' }}
          <img
            src="assets/img/icon-tooltip.svg"
            height="17"
            width="17"
            class="ml-1 mb-2"
            appTooltip mensagem="{{ (this.currentProduct.idProdutoTipo ===  this.productTypeEnum.eCPF || this.currentProduct.idProdutoTipo ===  this.productTypeEnum.ePF) ? 
              'CPF vinculado ao Registro Profissional Contábil.' : 
              'CNPJ vinculado à Organização Contábil.' }}"
              posicao="bottom"
        />
        </label>
        <input
          id="documento"
          class="form-control"
          formControlName="documento"
          mask="{{ (this.currentProduct.idProdutoTipo === this.productTypeEnum.eCPF || this.currentProduct.idProdutoTipo === this.productTypeEnum.ePF) ? '000.000.000-00' : '00.000.000/0000-00' }}"
        />
      </div>
      <div class="form-group mt-3">
        <label for="inputPassword5"
          >CRC
          <img
            src="assets/img/icon-tooltip.svg"
            height="17"
            width="17"
            class="ml-1 mb-2"
            appTooltip mensagem="Número do Registro Profissional Contábil ou da Organização Contábil." posicao="bottom"         
        /></label>
        <input
          type="text"
          id="inputPassword5"
          class="form-control"
          formControlName="registro"
          maxlength="6"
        />
        <small id="emailHelp" class="form-text text-muted"
          >Apenas números.</small
        >
      </div>
      <div class="d-flex justify-content-center mt-3">
        <re-captcha
          (resolved)="resolved($event)"
          siteKey="6LcdZj8gAAAAAONuGWdSo4weyViCN0P6NTVFtztZ"
        ></re-captcha>
      </div>
      <div class="mt-3">
        <button
          type="button"
          class="btn buscar"
          (click)="verificarCrc()"
          [disabled]="formConfirmarDados.invalid"
        >
          Buscar
        </button>
      </div>
    </form>
  </div>
</ng-template>
