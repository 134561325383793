import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CrcModel } from "src/app/shared/models/crc.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CrcService {
  private env = environment;
  constructor(private http: HttpClient) {}
  validarCrcCpf(data: any, token: string) {
    const headers = new HttpHeaders().set("Authorization", `${token}`);
    return this.http.post(
      `${this.env.API_SOLICITATION.link}/api/validacao/crc/cpf`,
      data,
      {
        headers,
      }
    );
  }
  validarCrcCnpj(data: any, token: string) {
    const headers = new HttpHeaders().set("Authorization", `${token}`);
    return this.http.post(
      `${this.env.API_SOLICITATION.link}/api/validacao/crc/cnpj`,
      data,
      {
        headers,
      }
    );
  }
  BuscaCrc(url: any) {
     return this.http.get<CrcModel>(
      `${this.env.API_SOLICITATION.link}/api/crc/BuscaCrc?url=${url}`
    );
  }
}
