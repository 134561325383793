import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
  

if (environment.production) {
  enableProdMode();
}

loadScript(environment.URL_WEBCOMPONENT_ECOMMERCE)

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  function loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src =  url;
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      document.body.appendChild(scriptElement);
    });
  }
