import { Component, OnInit } from '@angular/core';

import { CrcModel } from '../../shared/models/crc.model';
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-gratuidade',
  templateUrl: './gratuidade.component.html',
  styleUrls: ['./gratuidade.component.scss']
})
export class GratuidadeComponent implements OnInit {

  config: CrcModel;

  constructor(public dataService: DataService) {}

  ngOnInit(): void {
    this.config = this.dataService.getConfig();
  }
}
