import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthService } from "../services/authService";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let accessToken = sessionStorage.getItem("tokenAutentication");
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${accessToken}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
         if (error.status === 401) {
          return this.authService.autenticacao().pipe(
            switchMap((newToken) => {
              if (newToken) {
                sessionStorage.setItem("tokenAutentication", newToken);
                request = request.clone({
                  setHeaders: {
                    Authorization: `${newToken}`,
                  },
                });
                return next.handle(request);
              } else {
                // Handle refresh token failure here
                return throwError("Failed to refresh token");
              }
            }),
            catchError((refreshTokenError) => {
              // Handle refresh token failure here
              return throwError(refreshTokenError);
            })
          );
        }
        return throwError(error);
      })
    );
  }
}
