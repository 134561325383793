import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suporte-tecnico',
  templateUrl: './suporte-tecnico.component.html'
})
export class SuporteTecnicoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
