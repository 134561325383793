import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input('mensagem') tituloMensagem: string;
  @Input() posicao: string = 'bottom';
  @Input() delay: number = 500;
  mensagem: HTMLElement;

  offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.mensagem) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    console.log(this.mensagem)
    if (this.mensagem) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.mensagem, 'ng-tooltip-show');
  }

  hide() {
    this.renderer.removeClass(this.mensagem, 'ng-tooltip-show');
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.mensagem);
      this.mensagem = null;
    }, this.delay);
  }

  create() {
    this.mensagem = this.renderer.createElement('span');

    this.renderer.appendChild(this.mensagem, this.renderer.createText(this.tituloMensagem));

    this.renderer.appendChild(document.body, this.mensagem);

    this.renderer.addClass(this.mensagem, 'ng-tooltip');
    this.renderer.addClass(this.mensagem, `ng-tooltip-${this.posicao}`);
    this.renderer.addClass(this.mensagem, `ng-tooltip-indicator-to-${this.posicao}`);

    this.renderer.setStyle(this.mensagem, 'background', '#E8F2FF');
    this.renderer.setStyle(this.mensagem, 'color', '#575757');
    this.renderer.setStyle(this.mensagem, 'font', 'Roboto');
    this.renderer.setStyle(this.mensagem, 'font-size', '11px');

    // delay
    this.renderer.setStyle(this.mensagem, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.mensagem, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.mensagem, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.mensagem, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    // tooltip
    const tooltipPos = this.mensagem.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    if (this.posicao === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.posicao === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.posicao === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.posicao === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.mensagem, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.mensagem, 'left', `${left}px`);
  }
}
