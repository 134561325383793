import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { CrcModel } from "./shared/models/crc.model";
import { CrcService } from "./shared/services/crc.service";
import { DataService } from "./shared/services/data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loading = true;
  config: CrcModel;

  constructor(
    public router: Router,
    public dataService: DataService,
    public crcService: CrcService
  ) {}

  ngOnInit() {
    this.buscarUrl();
  }

  private buscarUrl() {
    const url = window.location.origin + window.location.pathname;
    this.crcService.BuscaCrc(url).subscribe(
      (result) => {
        this.dataService.setConfig(result);
        this.loading = false;
      },
      (err) => {
        console.log(`Ocorreu um erro ao buscar informações, ${err}`);
      }
    );
  }
}
