<section class="py-5 bg-product-section" id="gratuidade">
    <div class="container mb-3">
        <div class="row">
            <div class="col-md-12 col-12 text-center text-title mb-4">
                <h2 class="font-weight-bold mt-2 title">Requisitos para gratuidade</h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-sm-12 col-md-12">                

                <div class="row row-cols-1 row-cols-md-3 g-4">

                    <div class="col">
                        <div class="card text-white rounded-3">
                            <div class="card-body  d-flex justify-content-center align-items-center">
                                <p class="card-text line-height">Emissão gratuita de um e-CPF A1 e/ou um e-CNPJ A1 para profissionais da contabilidade e organizações contábeis</p>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card  text-white rounded-3">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <p class="card-text line-height">Necessário possuir registro ativo e regular perante {{ config?.Nome }} para garantir a gratuidade</p>
                            </div>
                        </div>
                    </div>

                    <div class="col ">
                        <div class="card text-white rounded-3">
                            <div class="card-body  d-flex justify-content-center align-items-center">
                                <p class="card-text line-height">O profissional/escritório deverá apresentar, no ato de validação, Certificado de Regularidade Profissional – CRP</p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>
