import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @ViewChild('myModal') myModal: ElementRef;
  constructor() { }

  ngOnInit() {
    jQuery('#myModal').modal('show');
  }
}
