import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authToken: string | null = null;
  private env = environment;
 
  constructor(private http: HttpClient) {}
 
  autenticacao() {
     return this.http.get<any>(`${this.env.API_CATALOGO.autenticacao}/Authenticate`);
  }
}
