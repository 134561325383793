<div class="card-product h-100 text-center">
  <img [src]="imagem" class="img-fluid mx-auto mt-4" alt="Safeweb" />

  <div class="card-body card-diferenciais">
    <h5 class="card-title-product mt-2" [innerHTML]="titulo"></h5>
    <img
      *ngIf="habilitarIconeVideoConf"
      src="assets/img/videoconf.svg"
      width="60"
      height="46"
      class="mr-3"
      style="position: absolute; top: 14px; right: 4px"
    />
    <ul class="list-group list-group-flush text-left">
      <li class="list-group-item-card" *ngFor="let d of diferenciais">
        <div class="d-flex justify-content-start">
          <img
            src="assets/img/icon-diferencial.svg"
            width="22"
            height="22"
            class="mr-3"
          />
          <span>{{ d }}</span>
        </div>
      </li>
    </ul>
  </div>

  <hr />
  <p class="mt-2">{{ labelPeriodo }}</p>
  <ul
    class="nav justify-content-center mb-5"
    *ngIf="produtos?.length > 0; else opcoesIndisponivel"
  >
    <li class="nav-item" *ngFor="let produto of produtos; let i = index">
      <button
        class="btn-validade-card mr-2"
        [ngClass]="{
          'btn-validade-focus':
            produtoSelecionado?.idEntidadeProduto == produto?.idEntidadeProduto
        }"
        (click)="selecionar(produto)"
      >
        {{ validade(produto) }}
      </button>
    </li>
  </ul>
  <ng-template #opcoesIndisponivel>
    <ul class="nav justify-content-center mb-5">
      <li class="nav-item">
        <button class="btn-indisponivel-card mr-2 btn-validade-focus">
          Indisponível
        </button>
      </li>
    </ul>
  </ng-template>

  <h3 class="card-price-product">{{ formatMoney(preco) }}</h3>
  <div class="d-flex justify-content-center">

    
    <button class="btn-product-buy mt-2 btn-block mb-5 text-uppercase" 
    (click)="comprar()" [disabled]="produtos?.length < 1" [ngStyle]="{'cursor': produtos?.length < 1 ? 'not-allowed' : 'pointer'}">Comprar</button>
    

  </div>
</div>
