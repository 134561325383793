import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contador-parceiro",
  templateUrl: "./contador-parceiro.component.html",
  styleUrls: ["./contador-parceiro.component.scss"],
})
export class ContadorParceiroComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
