import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../../environments/environment";
import { CrcModel } from "../../../shared/models/crc.model";
import { CrcService } from "../../../shared/services/crc.service";
import { DataService } from "../../../shared/services/data.service";
import { ProductFilterModel } from "./models/product-filter.model";
import { ProductGroupModel } from "./models/product-group.model";
import { ProductOptionModel } from "./models/product-option.model";
import { ProductTypeEnum } from "./models/product-type.enum";
import { ProductModel } from "./models/product.model";
import { ProdutoService } from "./produto.service";
import { TipoCrc } from "../../../shared/enums/TipoCrc";
import { CheckoutService } from "src/app/shared/services/checkout.service";
import { CatalogoService } from "src/app/shared/services/catalogo.service";

@Component({
  selector: "app-product",
  templateUrl: "./produto.component.html",
  styleUrls: ["./produto.component.scss"]
})
export class ProdutoComponent implements OnInit, OnDestroy {
  @ViewChild("modal", { static: false }) private modal;
  @ViewChild("modalConfirmacaoDados", { static: false })
  private modalConfirmacaoDados;
  @ViewChild("modalResultadoValidacaoCRC", { static: false })
  private modalResultadoValidacaoCRC;

  env: any;
  cnpjAR: any;
  navigationSubscription: any;
  linkPaginaEmissaoPresencialModal: string;
  mensagemResultadoValidacao: string;

  formConfirmarDados: UntypedFormGroup;
  products: ProductGroupModel[];
  currentProduct: ProductModel;
  productFilters: ProductFilterModel[];
  currentGroup: string;
  modalRef: BsModalRef;
  config: CrcModel;
  checkout = [];
  tokenAutenticacao;
  productTypeEnum = ProductTypeEnum;

  constructor(
    public dataService: DataService,

    private route: ActivatedRoute,
    private service: ProdutoService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private crcService: CrcService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private catalogoService: CatalogoService,
   
    private checkoutService: CheckoutService
  ) {
    this.checkoutService.checkout$.subscribe((newCheckout) => {
      this.checkout = newCheckout;
    });
  }

  ngOnInit() {
    this.env = environment;
    this.config = this.dataService.getConfig();
    this.productTypeEnum = ProductTypeEnum;

    this.cnpjAR = this.route.snapshot.queryParams.ar
      ? this.route.snapshot.queryParams.ar.trim()
      : null;

    this.formConfirmarDados = this.formBuilder.group({
      uf: [this.config.Uf],
      idTipoCrc: [1, Validators.required],
      idCrc: [0],
      idProdutoTipo: null,
      registro: [null, [Validators.required, Validators.minLength(4)]],
      recaptcha: [null, Validators.required],
      documento: [null, Validators.required],
    });

    this.checkout['catalogo'] = this.config.codigoCatalogo;
    this.checkout['cnpjAr'] =  this.config.CnpjAR;

   
    
    this.currentGroup = "CPF";
    this.getProductsEcommerce(this.config.codigoCatalogo);
    this.getDadosParceiro(this.config.codigoCatalogo, this.config.CnpjParceiro);
   }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  changeProductGroup(group: string) {
    this.currentGroup = group;
  }

  get eCpfs(): ProductOptionModel[] {
    const ecpfs = this.products?.find((x) => x.grupo === "e-CPF");
    if (ecpfs) {
      ecpfs.opcoes = this.removerProdutosZeroOpcoes(
        ecpfs.opcoes.sort(function (a, b) {
          return b.produtos[0].idTipoEmissao - a.produtos[0].idTipoEmissao;
        })
      );
    }
    return ecpfs?.opcoes;
  }

  get eCnpjs(): ProductOptionModel[] {
    const ecnpjs = this.products?.find((x) => x.grupo === "e-CNPJ");
    if (ecnpjs) {
      ecnpjs.opcoes = this.removerProdutosZeroOpcoes(
        ecnpjs.opcoes.sort(function (a, b) {
          return b.produtos[0].idTipoEmissao - a.produtos[0].idTipoEmissao;
        })
      );
    }
    return ecnpjs?.opcoes;
  }
  private removerProdutosZeroOpcoes(
    opcoesProduto: ProductOptionModel[]
  ): ProductOptionModel[] {
    return opcoesProduto?.filter((op) => {
      if (op?.produtos.length > 0) {
        return op;
      }
    });
  }

  private getProductsEcommerce(codigoCatalogo) {
    this.env.overlay = true;
    
    this.service.getProductsEcommerce(codigoCatalogo).subscribe(
      (result) => {
        this.products = result as ProductGroupModel[];
        this.env.overlay = false;
      },
      (err) => {
        this.env.overlay = false;
        this.toastr.error(`Ocorreu um erro ao buscar informações, ${err}`);
      }
    );
  }

  

  resolved(captchaResponse: string) {
    this.formConfirmarDados.get("recaptcha").setValue(captchaResponse);
  }

  produtoSelecionado(produto: ProductModel) {
    this.formConfirmarDados.get("registro").setValue(null);
    this.formConfirmarDados.get("documento").setValue(null);
    this.currentProduct = produto;
    this.modalRef = this.modalService.show(this.modalConfirmacaoDados, {
      class: "modal-confirmar-dados modal-dialog-centered",
      backdrop: true,
    });
  }

  verificarCrc() {
    let stringIdTipo;
    if (this.formConfirmarDados.get("idTipoCrc").value === TipoCrc.Originario) {
      stringIdTipo = "O";
    } else if (
      this.formConfirmarDados.get("idTipoCrc").value === TipoCrc.Filial
    ) {
      stringIdTipo = "F";
    } else {
      stringIdTipo = "P";
    }

    this.currentProduct.crc = {
      documento: null,
      idTipoCrc: this.formConfirmarDados.get("idTipoCrc").value,
      registro:
        this.formConfirmarDados.get("uf").value +
        this.formConfirmarDados.get("registro").value +
        stringIdTipo,
      idCrc: this.config.idCrc,
    };
    this.formConfirmarDados
      .get("idProdutoTipo")
      .setValue(this.currentProduct.idProdutoTipo);
    this.formConfirmarDados
      .get("idCrc")
      .setValue(this.currentProduct.crc.idCrc);
    this.env.overlay = true;
    if (
      this.currentProduct.idProdutoTipo === ProductTypeEnum.eCNPJ ||
      this.currentProduct.idProdutoTipo === ProductTypeEnum.ePJ
    ) {
      this.crcService
        .validarCrcCnpj(this.formConfirmarDados.getRawValue(), this.tokenAutenticacao)
        .subscribe(
          (retorno: any) => {
            this.env.overlay = false;
            if (this.validarRetornoInvalidoCrc(retorno)) {
              return;
            }
            this.currentProduct.crc.documento = retorno.documento;
            this.comprar();
          },
          (err: HttpErrorResponse) => {
            this.tratarErroRetornoValidacaoCrc(err);
          }
        );
    } else if (
      this.currentProduct.idProdutoTipo === ProductTypeEnum.eCPF ||
      this.currentProduct.idProdutoTipo === ProductTypeEnum.ePF
    ) {
      this.crcService
        .validarCrcCpf(this.formConfirmarDados.getRawValue(), this.tokenAutenticacao)
        .subscribe(
          (retorno: any) => {
            this.env.overlay = false;
            if (this.validarRetornoInvalidoCrc(retorno)) {
              return;
            }
            this.currentProduct.crc.documento = retorno.documento;
            this.comprar();
          },
          (err: HttpErrorResponse) => {
            this.tratarErroRetornoValidacaoCrc(err);
          }
        );
    }
  }

  private tratarErroRetornoValidacaoCrc(err: HttpErrorResponse) {
    this.env.overlay = false;
    this.modalRef?.hide();
    this.formConfirmarDados.get("registro").setValue(null);
    this.formConfirmarDados.get("documento").setValue(null);
    if (err.error) {
      if (
        err.error.Tipo == "ValidacaoCRCExecption" &&
        err.error.MensagemCliente
      ) {
        this.toastr.error(err.error.MensagemCliente, "Mensagem de erro", {
          positionClass: "toast-bottom-right",
        });
        return;
      } else if (err.error && err.error.MensagemCliente) {
        this.toastr.error(
          "Já existe um protocolo para o CRC informado. Em caso de dúvidas contate o suporte da Safeweb através do 0800-728-5900",
          "Mensagem de erro",
          {
            positionClass: "toast-bottom-right",
          }
        );
        return;
      }
    }

    this.toastr.error(
      "Ocorreu um erro ao realizar operação, tente novamente em alguns instantes.",
      "Mensagem de erro",
      {
        positionClass: "toast-bottom-right",
      }
    );
  }

  private validarRetornoInvalidoCrc(retorno: any): boolean {
    if (!retorno && !retorno?.documento) {
      this.toastr.error(
        `Verifique seus dados e tente novamente ou contate a Central de Relacionamento
             do CRCRJ através do WhatsApp (21) 965640134`,
        "Informações inválidas",
        {
          positionClass: "toast-bottom-right",
        }
      );
      return true;
    }
    return false;
  }

  public comprar() {
    console.log("Clicou em comprar");
    this.formConfirmarDados.get("registro").setValue(null);
    this.formConfirmarDados.get("documento").setValue(null);
    this.modalRef?.hide();
    environment.selectedProduct = this.currentProduct;
    this.checkout['produto'] = this.currentProduct;
    if(this.checkout['listaProdutos']){
    this.checkout['listaProdutos'] = this.checkout['listaProdutos'].filter((produto) => {
      return (
        produto.idCatalogoProduto ==
        this.currentProduct.idCatalogoProduto
      );
    });
  }
    this.env.selectedProduct.CnpjAR = this.cnpjAR;

     this.router.navigate(["/ecommerce", this.currentProduct.codigo], {
      queryParamsHandling: "preserve",
    });
  }

  private getDadosParceiro(codigoCatalogo, cnpj) {
     this.catalogoService.getDadosParceiro(codigoCatalogo, cnpj).subscribe(
      (result) => {
        this.checkout["dadosAr"] = result;
        this.getListaProdutos(codigoCatalogo, cnpj);
       },
      (err) => {
        this.toastr.error(`Ocorreu um erro ao buscar informações, ${err}`);
         this.env.overlay = false;
      }
    );
  }

  private getListaProdutos(codigoCatalogo, cnpj) {
     this.catalogoService.getListaProdutos(codigoCatalogo, cnpj).subscribe(
      (result) => {
        
         this.checkout["listaProdutos"] = result.map(item => {
          item.Valor = 0;
          return item;
         });
         this.env.overlay = false
       },
      (err) => {
         this.toastr.error(`Ocorreu um erro ao buscar informações: ${err}`);
        this.env.overlay = false
        }
    );
  }
}
