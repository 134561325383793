<section class="py-5 bg-product-section text-center">
    <div class="container mb-3">
        <div class="row">
            <div class="col-md-12 col-12 text-title mb-4">
                <h2 class="font-weight-bolder mt-2 title">Conheça o Contador Parceiro MAIS</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12"> 

                <p class="mb-4">
                    O Contador Parceiro Mais é o Programa de relacionamento da Safeweb com <br>diversas recompensas, criado especialmente para você, profissional contábil.
                </p>

                <p class="mb-4">
                    Para saber mais, acesse o link: <a href="https://contadorparceiromais.com.br" target="_blank"> contadorparceiromais.com.br</a>
                </p>      
                
                <img src="assets/img/logo-contador-parceiro.png" height="99" width="162">
            </div>
        </div>
    </div>
</section>