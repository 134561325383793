<div class="modal" id="myModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <img
        src="assets/img/logo-Safeweb-colorido.png"
        class="mx-auto"
        alt="LOGO SAFEWEB"
      />

      <div class="modal-body">
        <p>
          Atenção profissional da contabilidade com registro no CRCRJ.
          Temporariamente, nossos serviços estão passando por uma adequação.
        </p>
        <p>
          Para fazer a aquisição de seu Certificado Digital, entre em contato
          através do número:
        </p>
        <span> 0800 728 5900 </span>
      </div>

      <div class="modal-footer mx-auto">
        <button
          type="button"
          class="btn btn-primary btn-ok"
          data-dismiss="modal"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
