<section class="intro" id="intro">
  <div class="container-fluid p-0 img-bg">
    <div class="filtro"></div>
    <div class="row" style="top: 40%; right: 205px; position: absolute">
      <div
        class="col-md-12 text-left text-title card pl-5 pl-md-4 d-none d-sm-none d-md-block"
      >
        <h1>Parceria Safeweb e {{ config?.Nome }}</h1>
        <p style="font-size: 1.3rem">
          Valorizando o profissional da<br />
          contabilidade em situação regular.
        </p>
      </div>
    </div>
  </div>
</section>
