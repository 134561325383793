<section class="contact pt-4" id="suporte">
    <div class="container mb-5">
        <div class="row justify-content-center text-center">
            <div class="col-md-12 col-12">
                <h2 class="title font-weight-bold mb-5">Suporte Técnico</h2>
            </div>
            <div class="col-md-8 col-12">
                <p class="mb-0">
                    De segunda a sexta-feira, das 07h às 19h. Sábado das 07h às 12h.
                </p>
                <p class="mb-4">
                    <b>0800-728-5900 / (51) 3018.0300</b>
                </p>

                <p class="mb-0">
                    De segunda a sexta-feira, das 19h às 07h. Sábado e Domingo o dia inteiro.
                </p>
                <p class="mb-4">
                    <b>(51) 98189.0561 / (51) 98170.6185 / (51) 99209.6066</b>
                </p>
            </div>
        </div>
    </div>
</section>