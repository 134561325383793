<section class="py-5 bg-white" id="parceria">
    <div class="container mb-3">
        <div class="row">
            <div class="col-md-12 col-12 text-title mb-4">
                <h2 class="font-weight-bold mt-2  title">Parceria Safeweb e {{ config?.Nome }} concede um Certificado Digital A1 mediante regularidade profissional</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12"> 

                <p class="mb-4 line-height">
                    A Safeweb Segurança da Informação, uma das maiores Autoridades Certificadoras do país, com mais de 4 milhões de Certificados Digitais já emitidos e presente no mercado há mais de 24 anos, oferece diversas soluções em Certificação Digital.             ​
                </p>              
                
                <p class="mb-4 line-height font-weight-bold">
                    Em uma inovadora parceria com o Conselho Regional de Contabilidade ({{ config?.Nome }}), tornou-se credenciada em chamamento público e oferece, de forma gratuita, a emissão e renovação de um Certificado Digital A1 e-CPF ou e-CNPJ, para os profissionais com registro ativo e com certificado de Regularidade Profissional - CRP
                </p>

                <p class="mb-4 line-height">
                    O Certificado Digital ICP-Brasil disponível para pessoas (e-CPF) e empresas (e-CNPJ) funciona como uma identidade virtual que permite a identificação segura e inequívoca do autor de uma mensagem ou transação feita nos meios eletrônicos.​​
                </p>
                
                <p class="mb-4 line-height">
                    Emita seu Certificado Digital gratuitamente e conte com toda a estrutura Safeweb: mais de 1.400 locais de atendimento em todos os estados brasileiros e suporte técnico 24h por dia, sete dias por semana.
                </p> 
            </div>
        </div>
    </div>
</section>
