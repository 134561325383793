import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProdutoService {
  private env = environment;

  constructor(private http: HttpClient) {}

  getProductsEcommerce(codigoCatalogo: string) {

    return this.http
      .get(`${this.env.API_PRODUCTS.link}/catalogo/${codigoCatalogo}`)
      .pipe(catchError((err) => err));
  }
}
