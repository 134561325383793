import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CatalogoService {
  private env = environment;
  constructor(private http: HttpClient) {}

  getDadosParceiro(codigoCatalogo: string, cnpj: string) {
    return this.http.get<any>(
      `${this.env.API_CATALOGO.link}/GetParceiroCatalogo/${cnpj}/${codigoCatalogo}`
    );
  }

  getListaProdutos(codigoCatalogo: string, cnpj: string) {
    return this.http.get<any>(
      `${this.env.API_CATALOGO.link}/GetListCatalogoProduto/${cnpj}/${codigoCatalogo}`
    );
  }
}
