import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnlineComponent } from './pages/online/online.component';
import { EcommerceComponent } from './pages/ecommerce/ecommerce.component';

export const routes: Routes = [
  { path: '', component: OnlineComponent },
  { path: 'ecommerce/:id', component: EcommerceComponent },
  { path: '**', component: OnlineComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

