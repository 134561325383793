import { Component, OnInit } from '@angular/core';

import { CrcModel } from '../../shared/models/crc.model';
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-parceria-safeweb',
  templateUrl: './parceria-safeweb.component.html'
})
export class ParceriaSafewebComponent implements OnInit {

  config: CrcModel;

  constructor(public dataService: DataService) {}

  ngOnInit(): void {
    this.config = this.dataService.getConfig();
  }
}
